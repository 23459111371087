import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

import { formatImageAbsLink } from "../../lib/image"

const Facebook = ({
  url,
  siteName,
  pageType,
  title,
  description,
  image,
  imageAlt,
  locale,
  date,
  dateUpdated,
}) => (
  <Helmet>
    <meta property="og:site_name" content={siteName} />
    {pageType === "article" || pageType === "athlete" ? (
      <meta property="og:type" content="article" />
    ) : pageType === "person" ? (
      <meta property="og:type" content="profile" />
    ) : (
      <meta property="og:type" content="website" />
    )}
    <meta property="og:title" content={title} />
    <meta property="og:url" content={url} />
    <meta property="og:description" content={description} />
    <meta
      property="article:publisher"
      content="https://www.facebook.com/athlegan"
    />
    <meta property="og:locale" content={locale} />
    <meta property="og:image" content={formatImageAbsLink(image)} />
    <meta property="og:image:alt" content={imageAlt} />
    {date && <meta property="article:published_time" content={date} />}
    {dateUpdated && (
      <meta property="article:modified_time" content={dateUpdated} />
    )}
  </Helmet>
)

export default Facebook

Facebook.propTypes = {
  url: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  siteName: PropTypes.string,
  date: PropTypes.string,
  dateUpdated: PropTypes.string,
}

Facebook.defaultProps = {
  pageType: "page",
  siteName: "Athlegan",
}
