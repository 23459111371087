import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Facebook from "./facebook"
import Twitter from "./twitter"
import SchemaOrg from "./schema.org"
import SiteVerification from "./site-verification"

const SEO = ({
  title,
  description,
  pageType,
  pathName,
  banner,
  node,
  keywords,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            defaultTitle: title
            defaultDescription: description
            defaultBanner: banner
            siteLanguage
            ogLanguage
            twitter
            facebook
            keywords
            themeColor
          }
        }
      }
    `
  )

  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      siteLanguage,
      ogLanguage,
      twitter,
      facebook,
      themeColor,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathName || ""}`,
    keywords: keywords || site.siteMetadata.keywords,
  }

  let image, imageAlt
  switch (pageType) {
    case "article":
      if (node && node.titleImage) {
        image = node.titleImage.fluid.src
        imageAlt = node.titleImage.title
      }
      break

    // Interview
    case "athlete":
      if (node && node.backgroundImage) {
        image = node.backgroundImage.fluid.src
        imageAlt = node.backgroundImage.title
      }
      break

    // Team member
    case "person":
      if (node && node.avatarImage) {
        image = node.avatarImage.fluid.src
        imageAlt = node.avatarImage.title
      }
      break

    // Book
    // Recipe

    default:
      image = seo.image
      imageAlt = seo.description
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="HandheldFriendly" content="True" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        {/* <link rel="icon" type="image/x-icon" href="/favicon.ico"> */}
        <meta name="msapplication-TileColor" content={themeColor} />
        <meta
          name="msapplication-TileImage"
          content="/favicon/ms-icon-144x144.png"
        />
        <meta name="theme-color" content={themeColor} />
        <meta name="referrer" content="origin-when-cross-origin" />
      </Helmet>
      <SchemaOrg pageType={pageType} data={node} />
      <Facebook
        description={seo.description}
        image={image}
        imageAlt={imageAlt}
        title={seo.title}
        pageType={pageType}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={image}
        imageAlt={imageAlt}
        description={seo.description}
        userName={twitter}
        url={seo.url}
      />
      <SiteVerification />
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pageType: PropTypes.string,
  pathName: PropTypes.string,
  banner: PropTypes.string,
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  description: null,
  pageType: "page",
  pathName: null,
  banner: null,
  node: null,
}

export default SEO
