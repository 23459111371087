import React from "react"

import HeaderHome from "./header-home"
import HeaderPage from "./header-page"
import HeaderAthlete from "./header-athlete"
import HeaderArticle from "./header-article"

const Header = ({ pageType, header, backgroundFluid, author }) => {
  const HeaderComp =
    pageType === "home" ? (
      <HeaderHome background={backgroundFluid} />
    ) : pageType === "page" ? (
      <HeaderPage header={header} background={backgroundFluid} />
    ) : pageType === "athlete" ? (
      <HeaderAthlete header={header} background={backgroundFluid} />
    ) : (
      <HeaderArticle
        header={header}
        background={backgroundFluid}
        author={author}
      />
    )

  return HeaderComp
}

export default Header
