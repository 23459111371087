import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import HeaderNav from "./header-nav"

const Container = styled.header``

const HeaderPage = ({ header, background }) => (
  <Container>
    <div className="background">
      <Img fluid={background} />
    </div>
    <HeaderNav />
    <h1 className="title">{header}</h1>
  </Container>
)

export default HeaderPage
