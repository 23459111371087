import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { sendPageView, sendGAEvent } from "gatsby-plugin-analytics"
import scrollTo from "gatsby-plugin-smoothscroll"

import ContentfulDataContext from "../context/contentful-data"

import SEO from "./seo"
import Header from "./header/header"
import Footer from "./footer"

import config from "../../config/website"

// Global styles
import "../styles/global.css"

const Layout = ({
  location,
  pageSlug,
  pageType,
  pageClassName,
  pageContext,
  headerBackground,
  title,
  header,
  description,
  author,
  nodeData,
  children,
}) => {
  const data = useStaticQuery(graphql`
    query {
      athleganHeaderBG: file(
        relativePath: { eq: "athlegan-background-0.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1024, srcSetBreakpoints: [1024]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      athleganLogo: file(relativePath: { eq: "athlegan-logo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allContentfulPerson {
        edges {
          node {
            name
            slug
            tagline
            roles
            contentful_id
            avatarImage {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      allContentfulAsset {
        edges {
          node {
            contentful_id
            title
            description
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyContentfulFluid
            }
            file {
              url
            }
          }
        }
      }
      allContentfulArticle(sort: { order: DESC, fields: createdAt }) {
        edges {
          node {
            title
            slug
            category
            titleImage {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyContentfulFluid
              }
              title
            }
          }
        }
      }
      allContentfulInterview(sort: { order: DESC, fields: createdAt }) {
        edges {
          node {
            slug
            title
            header
            category
            excerpt {
              excerpt
            }
            backgroundImage {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyContentfulFluid
              }
              title
            }
          }
        }
      }
      allContentfulBook {
        edges {
          node {
            title
            slug
            category
            coverImage {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyContentfulFluid
              }
            }
            childContentfulMdx {
              childMdx {
                body
              }
            }
            excerpt {
              excerpt
            }
            rating
            contentful_id
          }
        }
      }
      allContentfulFood {
        edges {
          node {
            title
            slug
            category
            tags
            price
            weight
            nutrition {
              internal {
                content
              }
            }
            excerpt {
              excerpt
            }
            body {
              body
            }
            contentful_id
            review {
              slug
            }
          }
        }
      }
      allContentfulRecipe {
        edges {
          node {
            slug
            title
            ingredients
            directions
            excerpt {
              excerpt
            }
            body {
              body
            }
            nutrition {
              internal {
                content
              }
            }
            mealImage {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            childrenContentfulMdx {
              childMdx {
                body
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (pageContext.notFound) {
      sendGAEvent({
        event: "404 Not Found",
        value: location.pathname,
      })
    } else {
      sendPageView({
        location,
        pageContext,
      })
    }

    // Add hash links to headings
    const headings = document.querySelectorAll("h1, h2, h3, h4, h5, h6")
    for (const heading of headings) {
      if (!heading.id) {
        continue
      }

      heading.innerHTML += ` <a href="#${heading.id}" class="jump">#</a>`

      setTimeout(() => {
        heading.querySelector(".jump").onclick = () => {
          sendGAEvent({
            event: "Header Clicked",
            value: heading.id,
          })
        }
      })
    }

    // Scroll to hash
    if (location.hash) {
      setTimeout(() => {
        scrollTo(location.hash)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pageTypeSplit = pageType.split(",")
  const typeForPage = pageTypeSplit[0]
  const typeForSEO =
    pageTypeSplit.length > 1 ? pageTypeSplit[1] : pageTypeSplit[0]

  header = header ? header : title

  headerBackground =
    headerBackground && headerBackground.srcSet
      ? headerBackground
      : data.athleganHeaderBG.childImageSharp.fluid

  return (
    <ContentfulDataContext.Provider
      value={{
        ...data,
      }}
    >
      <SEO
        title={title}
        description={description}
        pageType={typeForSEO}
        pathName={(location && location.pathName) || pageSlug}
        node={nodeData}
      />
      <Header
        pageType={typeForPage}
        header={header}
        backgroundFluid={headerBackground}
        author={author}
      />
      <main className={pageClassName}>{children}</main>
      <Footer logoFluid={data.athleganLogo.childImageSharp.fluid} />
    </ContentfulDataContext.Provider>
  )
}

Layout.propTypes = {
  location: PropTypes.object,
  pageSlug: PropTypes.string,
  pageType: PropTypes.string,
  pageClassName: PropTypes.string,
  pageContext: PropTypes.object,
  headerBackground: PropTypes.object,
  title: PropTypes.string,
  header: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.object,
  nodeData: PropTypes.object,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  location: {},
  pageSlug: "",
  pageType: "home",
  pageClassName: "",
  pageContext: {},
  headerBackground: {},
  title: config.title,
  description: config.description,
}

export default Layout
