import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import HeaderNav from "./header-nav"

import { subscribe } from "../../lib/mailer-lite"

const Container = styled.header`
  font-size: 1.5em;
  min-height: 100vh;

  .title {
    padding-right: 30%;
  }

  .intro {
    font-style: italic;
    line-height: 1.5em;
  }

  .cta {
    margin-bottom: 1em;
    margin-top: 1em;
    text-align: right;

    button.subscribe-button {
      border-bottom: 1px solid #ffffff;
      color: #ffffff;

      &:hover {
        border-bottom: 1px solid #669966;
        color: #669966;
      }
    }
  }
`

const HeaderHome = ({ background }) => (
  <Container>
    <div className="background">
      <Img fluid={background} />
    </div>
    <HeaderNav />
    <h1 className="title">Be a strong vegan</h1>
    <p className="intro">
      Learn how to train and eat to grow stronger, faster, and fitter. Become an
      example of how powerful one can be without causing harm to others.
    </p>
    <p className="cta">
      <a
        className="subscribe-button"
        role="button"
        onClick={e => {
          subscribe(e)
        }}
        onKeyDown={e => {
          subscribe(e)
        }}
        href="/subscribe"
      >
        Join us
      </a>
    </p>
  </Container>
)

export default HeaderHome
