import stripHtml from "string-strip-html"

import config from "../../../../config/website"

import { formatImageAbsLink } from "../../../lib/image"

const getSchemaTeamMember = data => {
  const { name, slug, tagline, avatarImage } = data

  const schema = [
    {
      "@context": "https://schema.org",
      "@type": "Person",
      url: `${config.url}/team/${stripHtml(slug)}`,
      name: stripHtml(name),
      description: stripHtml(tagline),
      image: {
        "@type": "ImageObject",
        url: formatImageAbsLink(avatarImage.fluid.src),
        description: avatarImage.title,
      },
      affiliation: {
        "@type": "Organization",
        name: "Athlegan",
        url: `${config.url}`,
        logo: {
          "@type": "ImageObject",
          url: `${config.url}${config.logo}`,
        },
        sameAs: [
          "https://www.facebook.com/athlegan",
          "https://twitter.com/athlegan",
          "https://www.instagram.com/athlegan/",
        ],
      },
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": `${config.url}/team`,
            name: "Meet the team",
          },
        },
      ],
    },
  ]

  return schema
}

export default getSchemaTeamMember
