import React from "react"
import Helmet from "react-helmet"

const SiteVerification = () => (
  <Helmet>
    <meta name="p:domain_verify" content="0bce7a0c7f579a2b4b7845a8ffab9068" />
    <meta
      name="google-site-verification"
      content="apZiGgWbewwi7t0EvM0cmfoJVXv3Eb2E5W-q_XNlA7g"
    />
    <meta
      name="pocket-site-verification"
      content="ab41fe562f878447503b891291e71c"
    />
  </Helmet>
)

export default SiteVerification
