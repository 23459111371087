import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

import { formatImageAbsLink } from "../../lib/image"

const Twitter = ({
  userName,
  cardType,
  title,
  description,
  url,
  image,
  imageAlt,
}) => (
  <Helmet>
    {userName && <meta name="twitter:creator" content={userName} />}
    <meta name="twitter:site" content={userName} />
    <meta name="twitter:card" content={cardType} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={formatImageAbsLink(image)} />
    <meta name="twitter:image:alt" content={imageAlt} />
    <meta name="twitter:url" content={url} />
  </Helmet>
)

export default Twitter

Twitter.propTypes = {
  cardType: PropTypes.string,
  userName: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
}

Twitter.defaultProps = {
  cardType: "summary_large_image",
  userName: null,
}
