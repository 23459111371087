module.exports = {
  pathPrefix: "",
  title: "Athlegan", // Navigation and Site Title
  titleAlt: "Athlegan athlegan.com", // Title for JSONLD
  description: "Building muscles without eating others'!",
  url: "https://www.athlegan.com", // Domain of your site. No trailing slash!
  siteAuthor: "@tobiassjosten",
  siteLanguage: "en", // Language Tag on <html> element
  logo: "/images/athlegan-logo.png", // Used for SEO
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "/favicon/favicon-32x32.png", // Used for manifest favicon generation
  shortName: "Athlegan", // shortname for manifest. MUST be shorter than 12 characters
  author: "Tobias Sjösten", // Author for schemaORGJSONLD
  themeColor: "#ffffff",
  backgroundColor: "#ffffff",

  twitter: "@athlegan",
  facebook: "athlegan",
  instagram: "athlegan",
  pinterest: "athlegan",
  youtubeChannel: "UCnpK_W_CQh6b-xpoEus_jUw",
}
