import stripHtml from "string-strip-html"

import config from "../../../../config/website"

import { formatImageAbsLink } from "../../../lib/image"

const getSchemaArticle = data => {
  const {
    title,
    slug,
    excerpt,
    category,
    titleImage,
    createdAt,
    updatedAt,
    author,
  } = data

  const schema = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: stripHtml(title),
    url: `${config.url}/${stripHtml(slug)}`,
    description: stripHtml(excerpt ? excerpt.excerpt : ""),
    genre: category,
    datePublished: createdAt,
    publisher: {
      "@type": "Organization",
      name: "Athlegan",
      url: `${config.url}`,
      logo: {
        "@type": "ImageObject",
        url: `${config.url}${config.logo}`,
      },
      sameAs: [
        "https://www.facebook.com/athlegan",
        "https://twitter.com/athlegan",
        "https://www.instagram.com/athlegan/",
      ],
    },
  }

  if (updatedAt) {
    schema.dateModified = updatedAt
  }

  if (titleImage) {
    schema.image = {
      "@type": "ImageObject",
      url: formatImageAbsLink(titleImage.fluid.src),
      description: titleImage.title,
    }
  }

  if (author) {
    schema.author = {
      "@type": "Person",
      url: `${config.url}/team/${author.slug}`,
      name: stripHtml(author.name),
      description: stripHtml(author.tagline),
      image: {
        "@type": "ImageObject",
        url: formatImageAbsLink(author.avatarImage.fluid.src),
      },
    }
  }

  return schema
}

export default getSchemaArticle
