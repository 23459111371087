import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import getSchemaPage from "./page"
import getSchemaArticle from "./article"
import getSchemaTeamMember from "./team-member"
import getSchemaAthlete from "./athlete"

const SchemaOrg = ({ pageType, data }) => {
  let schema

  switch (pageType) {
    case "article":
      schema = getSchemaArticle(data)
      break

    case "person":
      schema = getSchemaTeamMember(data)
      break

    case "athlete":
      schema = getSchemaAthlete(data)
      break

    default:
      schema = getSchemaPage(data)
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default SchemaOrg

SchemaOrg.propTypes = {
  pageType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
